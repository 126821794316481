import { useState, useRef } from "react";
import Header from "./components/Header";
import Section from "./components/Section";
import { BsHeartFill, BsHeart } from "react-icons/bs";
import "./App.css";

const texts = [
  "Par une douce nuit,_J'ai trouvé les clefs de ton cœur,_Lorsque je suis entré sans bruit,_J'ai forcé les portes du bonheur !",
  "Avec toi juste une nuit,_J'ai aperçu un recoin de paradis !_Comme un jardin glamour,_Où pousseraient les fleurs de ton amour !",
  "Crois-moi, nous serons unis,_Tant que l'amour portera ses fruits !_Peut-être pour l'infini,_Le jour comme la nuit !",
  "Aurais-je ma place au paradis ?_Mais si tu veux de moi,_Je me ferai tout petit,_Pour une nuit, une vie avec toi !",
];

function App() {
  const [playAudio, setPlayAudio] = useState(false);
  const audioRef = useRef();

  const play = () => {
    if (!playAudio) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    setPlayAudio(!playAudio);
  };

  return (
    <main className="container">
      {playAudio ? (
        <BsHeartFill className="heart" onClick={play} />
      ) : (
        <BsHeart className="heart" onClick={play} />
      )}
      <Header title="Elodie" />
      {playAudio && texts.map((text, i) => (
        <Section key={`text-${i}`} text={text} />
      ))}
      <audio
        id="song"
        ref={audioRef}
        src="/assets/all-of-me-john-legend-lindsey-stirling.mp3"
      >
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    </main>
  );
}

export default App;
